import React from "react";
import MainLayout from "../components/MainLayout";
import Blogs from "../sections/homepage/Blogs";
import BlogStatic from "../sections/homepage/Blogs/Blogs";

const Blog = () => {
  return (
    <MainLayout width={0}>
      <BlogStatic />
    </MainLayout>
  );
};

export default Blog;
