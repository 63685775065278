import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import parse from "html-react-parser";
import "./Blogs.scss";
import { Container, Row, Col, Card } from "react-bootstrap";
interface Data {
  excerpt: string;
  title: string;
  uri: string;
}

interface IProps {
  data: Data[];
}

// const Blogs: React.FC<IProps> = ({ data }) => {
//   return (
//     <div className="blogsContainer">
//       <h1 className="h1 text-center">Blogs</h1>
//       <div className="spacer-sm"></div>
//       <Container>
//         <Row className="align-items-stretch">
//           {data.map((blog, index) => (
//             <Col key={index} md={4}>
//               <Card className="border-0 shadow-none">
//                 <Card.Body className="bg-light shadow-none">
//                   <Card.Title className="font-weight-bold text-dark">
//                     {parse(blog.title)}
//                   </Card.Title>
//                   <Card.Text className="text-dark">
//                     {parse(blog.excerpt)}
//                   </Card.Text>
//                 </Card.Body>
//                 <Card.Footer className="border-0 bg-light shadow-none">
//                   <Link to={blog.uri} itemProp="url">
//                     <a href="#" className="d-flex m-0">
//                       <h6>
//                         Read more
//                         <img
//                           className="ml-2"
//                           src="../icons/ic_small_next_arrow.svg"
//                           alt="Right arrow"
//                         />
//                       </h6>
//                     </a>
//                   </Link>
//                 </Card.Footer>
//               </Card>
//             </Col>
//           ))}
//         </Row>
//       </Container>
//     </div>
//   );
// };

const BlogStatic: React.FC = () => {
  return (
    // <StaticQuery
    //   query={graphql`
    //     query LatestBlogs {
    //       allWpPost(
    //         sort: { fields: [date], order: DESC }
    //         limit: 6
    //         filter: {
    //           categories: {
    //             nodes: { elemMatch: { id: { ne: "dGVybToyNzM=" } } }
    //           }
    //         }
    //       ) {
    //         nodes {
    //           excerpt
    //           uri
    //           date(formatString: "MMMM DD, YYYY")
    //           title
    //           categories {
    //             nodes {
    //               name
    //             }
    //           }
    //           featuredImage {
    //             node {
    //               altText
    //               localFile {
    //                 childImageSharp {
    //                   fluid(maxWidth: 1000, quality: 100) {
    //                     ...GatsbyImageSharpFluid_tracedSVG
    //                   }
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   `}
    //   render={(data) => <Blogs data={data.allWpPost.nodes} />}
    // />
    <p>Blog Static</p>
  );
};
export default BlogStatic;
